<template>
	<div class="center" style="text-align: center">
		<img width="250" height="250" src="/img/acara_logo_2.png" />
		<h4 class="title"><b>Acceso Denegado</b></h4>
		<p>Usted no tiene permisos para acceder a esta sección.</p>
		<router-link :to="{ name: 'dashboard' }"> Volver al inicio </router-link>
	</div>
</template>

<script>
	export default {};
</script>

<style scoped>
.title {
	margin-top: 10px;
}
.center {
	margin-top: 5%;

	padding: 10px;
}
</style>
